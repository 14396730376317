import {
  FluentProvider,
  webLightTheme,
  Link,
  Caption1,
  CardHeader,
  Body1,
  Card,
  Body2,
  LargeTitle,
  Toolbar,
  ToolbarButton,
  Divider,
  Persona,
  Image,
} from "@fluentui/react-components";
import "./App.css";
import { useRef } from "react";
import profile from "./images/profile.png";
import spc from "./images/SPC.png";
import skills1 from "./images/skills1.png";
import skills2 from "./images/skills2.png";
import ig from "./images/ig.png";
import td from "./images/td.png";
import ability from "./images/ability.png";
import genie from "./images/genie.png";
import verizon from "./images/verizon.png";
import gs from "./images/gs.png";
import linkedin from "./images/linkedin.png";

export default function App() {
  const elementRef1 = useRef<HTMLDivElement>(null);
  const elementRef2 = useRef<HTMLDivElement>(null);
  const elementRef3 = useRef<HTMLDivElement>(null);
  const elementRef4 = useRef<HTMLDivElement>(null);
  const handleClick1 = () => {
    if (elementRef1.current != null) {
      elementRef1.current.scrollIntoView();
    }
  };
  const handleClick2 = () => {
    if (elementRef2.current != null) {
      elementRef2.current.scrollIntoView();
    }
  };
  const handleClick3 = () => {
    if (elementRef3.current != null) {
      elementRef3.current.scrollIntoView();
    }
  };
  const handleClick4 = () => {
    if (elementRef4.current != null) {
      elementRef4.current.scrollIntoView();
    }
  };
  return (
    <FluentProvider theme={webLightTheme}>
      <div className="App">
        <div className="ToolbarHeader">
          <Toolbar size="small" className="Toolbar">
            <ToolbarButton onClick={handleClick1}>Education</ToolbarButton>
            <ToolbarButton onClick={handleClick2}>Skills</ToolbarButton>
            <ToolbarButton onClick={handleClick3}>Experience</ToolbarButton>
            <ToolbarButton onClick={handleClick4}>Contact</ToolbarButton>
          </Toolbar>
        </div>
        <Image
          className="Avatar"
          alt="Adriel Lopez"
          src={profile}
          shape="circular"
          onClick={handleClick4}
        />
        <br></br>
        <LargeTitle>Adriel Lopez</LargeTitle>
        <br></br>
        <Caption1>Web Developer</Caption1>
        <br></br>
        <div className="Intro">
          <Body2 align="start">
            I'm a web developer with a strong foundation in quality assurance
            and cybersecurity. My journey into the digital realm began with a
            focus on ensuring software security and functionality. Leveraging my
            expertise in these areas, I'm committed to crafting web solutions
            that not only perform flawlessly but also safeguard the digital
            landscape.
          </Body2>
        </div>
        <br></br>
        <div ref={elementRef1}></div>
        <Divider className="Divider">Education</Divider>
        <Card className="Card">
          <CardHeader
            image={<img className="ResumeLogo" src={spc} alt="SPC Logo" />}
            header={
              <Body1>
                <b>St. Petersburg College</b> Graduated in 2022
                <br></br>
                <i>
                  Bachelor's Degree in Technology Development and Management
                </i>
                <ul>
                  <li>
                    <b>Certifications:</b> A+, Security+
                  </li>
                  <li>
                    Proficient in Windows XP-11, macOS 10.6-12.6, Android, iOS,
                    Linux, and Cisco IOS
                  </li>
                  <li>
                    Familiar with React, Fluent, Typescript, HTML, Azure DevOps,
                    SQL, and x12 EDI
                  </li>
                </ul>
              </Body1>
            }
          />
        </Card>
        <div ref={elementRef2}></div>
        <Divider className="Divider">Skills</Divider>
        <Card className="Card">
          <CardHeader
            image={
              <img className="ResumeLogo" src={skills1} alt="Skills Logo" />
            }
            header={
              <Body1>
                <b>Web Development</b>
                <br></br>
                <ul>
                  <li>
                    <b>React.js:</b> Proficient in React and its core concepts
                    for building and maintaining web applications
                  </li>
                  <li>
                    <b>JavaScript:</b> Strong command of JavaScript
                  </li>
                  <li>
                    <b>HTML/CSS:</b> Skilled in using HTML and CSS to structure
                    and style web pages
                  </li>
                  <li>
                    <b>Responsive Web Design:</b> Proficient in creating
                    responsive web applications for various devices.
                  </li>
                  <li>
                    <b>Version Control/Git:</b> Experienced in version control
                    with Git and collaborative development on GitHub.
                  </li>
                  <li>
                    <b>Package Managers:</b> Proficient in using npm and Yarn
                    for dependency management
                  </li>
                  <li>
                    <b>Component-Based Architecture:</b> Capable of designing
                    component-based architectures in React
                  </li>
                </ul>
              </Body1>
            }
          />
        </Card>
        <Card className="Card">
          <CardHeader
            image={
              <img className="ResumeLogo" src={skills2} alt="Skills Logo" />
            }
            header={
              <Body1>
                <b>QA Engineer</b>
                <br></br>
                <ul>
                  <li>
                    <b>Test Planning:</b> Proficient in creating comprehensive
                    test plans and strategies for software testing.
                  </li>
                  <li>
                    <b>Defect Tracking:</b> Competent in tracking, reporting,
                    and managing defects
                  </li>
                  <li>
                    <b>API Testing:</b> Capable of testing APIs for
                    functionality, security, and performance.
                  </li>
                  <li>
                    <b>SQL:</b> Proficient in writing and executing SQL queries
                    for database testing.
                  </li>
                  <li>
                    <b>Security Testing:</b> Knowledge of security testing
                    techniques and tools to identify vulnerabilities in
                    software.
                  </li>
                  <li>
                    <b>Cross-Browser/Device Testing:</b> Experience in testing
                    web applications across different browsers and devices for
                    compatibility.
                  </li>
                </ul>
              </Body1>
            }
          />
        </Card>
        <div ref={elementRef3}>
          <Divider className="Divider">Experience</Divider>
          <Card className="Card">
            <CardHeader
              image={
                <img
                  className="ResumeLogo"
                  src={ig}
                  alt="Insight Global Logo"
                />
              }
              header={
                <Body1>
                  <b>Insight Global</b> May 2022 - Present
                  <br></br>
                  <i>QA Analyst</i>
                  <ul>
                    <li>
                      Collaborated with developers and business analysts to
                      understand new requirements
                    </li>
                    <li>
                      Conducted comprehensive end-to-end functionality testing
                      for various applications
                    </li>
                    <li>
                      Generated detailed reports outlining testing outcomes and
                      supporting evidence
                    </li>
                    <li>
                      Engaged in stand-up and sprint meetings to share updates
                      and address challenges
                    </li>
                    <li>
                      Developed meticulous test approach and test plan
                      documentation
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img className="ResumeLogo" src={td} alt="Tech Data Logo" />
              }
              header={
                <Body1>
                  <b>Tech Data</b> December 2020 - December 2021
                  <br></br>
                  <i>QA Analyst</i>
                  <ul>
                    <li>
                      Worked closely with developers and stakeholders to clarify
                      project requirements
                    </li>
                    <li>
                      Devised comprehensive plans for system testing based on
                      detailed requirement analysis
                    </li>
                    <li>
                      Translated user stories into practical test deliverables:
                      test cases and test scripts
                    </li>
                    <li>
                      <b>
                        Gained valuable insights through job shadowing with a
                        Security Analyst
                      </b>
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img className="ResumeLogo" src={td} alt="Tech Data Logo" />
              }
              header={
                <Body1>
                  <b>Tech Data</b> March 2019 - December 2020
                  <br></br>
                  <i>Lead Product Specialist</i>
                  <ul>
                    <li>
                      Provided leadership for Specialist team and facilitated
                      productive meetings
                    </li>
                    <li>
                      Identified and prioritized defects within the cloud
                      platform
                    </li>
                    <li>
                      Supported client-facing business team in delivering
                      optimal solutions
                    </li>
                    <li>
                      Escalated issues to software development teams for timely
                      resolution
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img
                  className="ResumeLogo"
                  src={ability}
                  alt="Ability Network Logo"
                />
              }
              header={
                <Body1>
                  <b>Ability Network</b> September 2018 - March 2019
                  <br></br>
                  <i>Technical Support</i>
                  <ul>
                    <li>
                      Diagnosed issues related to EDI Transmission and SaaS
                      Products
                    </li>
                    <li>
                      Managed and resolved technical issues on a case-by-case
                      basis
                    </li>
                    <li>
                      Tracked case queues and identified emerging trends for
                      proactive action
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img
                  className="ResumeLogo"
                  src={genie}
                  alt="Genie Computer Systems Logo"
                />
              }
              header={
                <Body1>
                  <b>Genie Computer Systems</b> August 2017 - September 2018
                  <br></br>
                  <i>Lead Technician</i>
                  <ul>
                    <li>
                      Monitored security status of client endpoints to ensure
                      optimal protection
                    </li>
                    <li>
                      Provided on-site setup and maintenance for small to medium
                      enterprise networks
                    </li>
                    <li>
                      Delivered remote technical support, adhering to Service
                      Level Agreements
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img
                  className="ResumeLogo"
                  src={verizon}
                  alt="Verizon Wireless Logo"
                />
              }
              header={
                <Body1>
                  <b>Verizon Wireless</b> February 2015 - September 2016
                  <br></br>
                  <i>Bilingual Customer Advocate</i>
                  <ul>
                    <li>
                      Tier 1 Technical support of mobile devices and network
                      troubles
                    </li>
                    <li>
                      Interpreted network logs to diagnose and troubleshoot
                      customer issues
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
          <Card className="Card">
            <CardHeader
              image={
                <img className="ResumeLogo" src={gs} alt="Geek Squad Logo" />
              }
              header={
                <Body1>
                  <b>Geek Squad</b> October 2014 - January 2015
                  <br></br>
                  <i>Advanced Repair Agent </i>
                  <ul>
                    <li>
                      Conducted maintenance, repair, and servicing of client
                      devices
                    </li>
                    <li>
                      Demonstrated product operability and ensured timely return
                      to clients
                    </li>
                  </ul>
                </Body1>
              }
            />
          </Card>
        </div>
        <div className="Footer" ref={elementRef4}>
          <Persona
            name="Adriel Lopez"
            size="huge"
            avatar={{
              image: {
                src: profile,
              },
            }}
            presence={{ status: "available" }}
            secondaryText={"(813) 520-9375"}
            tertiaryText={
              <Link href="mailto:adriel@sptechrepair.com">
                adriel@sptechrepair.com
              </Link>
            }
          />

          <Link
            target="_blank"
            href="https://www.linkedin.com/in/adriel-lopez-95146b1ab/"
          >
            <img className="LinkedInLogo" alt="LinkedIn" src={linkedin} />
          </Link>
        </div>
      </div>
    </FluentProvider>
  );
}
